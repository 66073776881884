.post-editor {
    height: 100%;
    width: 100%;
}

.post-editor-container {
    overflow: auto;
    padding: 1.5em;
}

.post-editor-container h1 {
    margin-top: 0;
}

label {
    display: block;
}

.post-editor-footer {
    padding: 1em;
    display: flex;
    justify-content: space-between;
    background: white;
    border-top: 1px solid lightgray;
}

.right-edit-content {
    border-top: 1px solid lightgray;
    padding: 1em 0;
    /* background: white; */
}

.saved-now {
    display: flex;
    align-items: center;
    flex: 1;
}