.IconNav {
    width: 50px;
}

.post-list-page {
    width: 100%;
}

.post-list-page-h1 {
    margin-left: 1em;
}

.post-container {
    overflow: auto;
    border-top: 1px solid lightgrey;
}

.LeftNav {
    flex: 1;
    height: 100%;
    /* overflow: scroll; */
    font-size: 12px;
}

.RightPart {
    flex: 3;
    /* padding: 2em; */
    border-left: 1px solid lightgray;
    overflow: hidden;
    background: #F9FAFB;
    display: flex;
}

.left-nav-list {
    height: 100%;
    overflow: auto;
}

.left-nav-tabs {
    border-bottom: 1px solid lightgray;
}

.left-nav-list-btn {
    margin: 1em;
    padding: 1em;
    text-align: center;
    background: #A75EB3;
    color: white;
    cursor: pointer;
}

.no-tool-selected {
    margin: auto;
    text-align: center;
}



.tag,
.tag-grey {
    display: inline-block;
    padding: 0.5em 1em;
    font-size: 12px;
    color: white;
    background: black;
    background: #a75eb3;
    border-radius: 15px;
}

.tag-grey {
    background: lightgray;
    color: black;
}

.left-nav-list-section {
    color: #7B8595;
    padding: 10px;
    font-weight: bold;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.left-nav-list-section.active-section {
    color: white;
    background: #A75EB3;
}

ul {
    list-style-type: none;
    padding: 0;
}

ul li {
    padding: 0 5px;
}

label {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 0.5em;
}

a {
    display: block;
    padding: 10px;
    transition: all 0.3s ease;
    text-decoration: none;
    color: #7B8595;
}

a:hover,
.active-tool {
    background: lightgray;
    color: black;
}

.sub-label {
    font-size: 12px;
    color: grey;
    font-style: italic;
    padding: 10px 0 0 0;
}