.right-content-container {
    padding: 2em;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.right-content {
    overflow: auto;
    /* background: white; */
    padding: 1em;
    border: 1px solid lightgray;
}

.post-viewer-title {
    margin-top: 0;
}