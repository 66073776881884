.LeftIconNav {
    /* width: 50px; */
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid lightgray;
}

.LeftIconNav svg {
    /* display: block;
    margin-bottom: 1em; */
}

.left-nav-icon-item a {
    /* padding: 1em; */
    transition: all 0.3s ease;
    cursor: pointer;
}

.left-nav-icon-item a:hover {
    background-color: #BA68C8;
    color: white;
}

.left-nav-icon-item a.active {
    background-color: #A75EB4;
    color: white;
}