.editorClassName {
    padding: 1em;
    background: white;
    max-height: 350px;
    /* margin-bottom: 40px; */
}

.rdw-editor-toolbar {
    margin: 0 !important;
    /* border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-top: 1px solid lightgray; */
}

.rdw-editor-wrapper {
    border: 1px solid lightgray;
}