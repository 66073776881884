body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  /* color: #7B8599; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100% !important;
}

.snipp-flex-row {
  display: flex;
}

.snipp-flex-col {
  display: flex;
  flex-direction: column;
}

.snipp-flex-1 {
  flex: 1;
}

.flex-justify-space-between {
  justify-content: space-between;
}

/* Button Styles */
.btn {
  background: black;
  color: white;
  padding: 0.5em 1em;
  border-radius: 5px;
  border: 1px solid black;
  transition: all 0.3s ease;
}

.btn:hover {
  background: rgba(0, 0, 0, 0.7);
}

.btn:disabled {
  background-color: grey;
  border: 1px solid grey;
  cursor: not-allowed;
}

.btn.secondary {
  background: transparent;
  color: black;
  border: 0;
}

.btn.secondary:hover {
  background-color: lightgray;
}

.inline-label {
  display: inline-block;
}

textarea {
  padding: 1em;
  border-radius: 4px;
  border: 1px solid lightgray;
}

input[type="checkbox"] {
  margin: 0;
  margin-right: 10px;
}

input[type="text"],
input[type="password"] {
  padding: 1em;
  border-radius: 4px;
  border: 1px solid lightgray;
  width: 250px;
  box-sizing: border-box;
}

input.long {
  width: 400px;
}

input.inline {
  padding: 0.5em;
  width: 100%;
  height: 30px;
}

.mar-left-20 {
  margin-left: 20px;
}

.mar-bot-20 {
  margin-bottom: 20px;
}

.mar-bot-40 {
  margin-bottom: 40px;
}

.content-container {
  padding: 0 2em 2em 2em;
  width: 100%;
}