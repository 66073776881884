.snipp-tab-bar {}

.snipp-tab-bar ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.snipp-tab-bar ul li {
    padding: 0.5em 1em;
    display: inline-block;
    color: #7B8595;
    cursor: pointer;
    border-left: 1px solid lightgray;
    border-top: 1px solid lightgray;
}

.snipp-tab-bar ul li:last-child {
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-top: 1px solid lightgray;
}

.snipp-tab-bar ul li:hover {
    background: white;
}

.snipp-tab-bar ul li.active-tab {
    border-bottom: 2px solid black;
    background: white;
    color: black;
    cursor: not-allowed;
}

.snipp-tab-bar ul li.active-tab::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    background: black;
    height: 2px;
    left: 0;
    bottom: 0;
}