.settings-tab-content {
    border: 1px solid lightgray;
    overflow: auto;
    background: white;
    padding: 1em;
}

.settings-tab-content-container {
    overflow: auto;
}

.settings-const-table-container {
    padding: 10px;
}

.settings-const-table th {
    padding: 10px 0 10px 5px;
    text-align: left;
    background: lightgrey;
}

.settings-const-table td {
    padding: 10px;
}

.settings-const-table tbody tr:nth-child(even) {
    background: #F9FAFB;
}

.settings-const-table tbody tr:hover {
    background: #A75EB3;
    color: white;
}