.snipp-dialog::backdrop {
    background: hsl(0 0% 0% / 50%);
}

.snipp-dialog {
    min-width: 40%;
    max-width: 20rem;
    padding: 1rem;
    border: 0;
    border-radius: 0.5rem;
    position: relative;
    box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
}

.modal-close-btn {
    font-size: 0.75em;
    position: absolute;
    top: 0.25em;
    right: 0.25em;
}

.snipp-dialog-title {
    font-size: 1.5em;
}

.snipp-dialog-footer {
    justify-content: flex-end;
}