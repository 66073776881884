.LoginPage {
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
}

.LoginContainer {
    background: #F9FAFB;
    box-shadow: 1px 1px 6px 0px lightgray;
    border-radius: 5px;
    padding: 2em;
}

.err-color {
    color: crimson;
}