.snipp-flex-3 {
    flex: 3;
}

.snipp-textarea {
    resize: none;
    width: 100%;
    box-sizing: border-box;
}

.sub-text {
    color: #7B8595;
    font-size: 12px;
}

.right-side-form {
    background: white;
    padding: 1em;
    border-radius: 4px;
    box-shadow: 0px 0px 2px #bdc1c9
}

.right-side-form label {
    font-size: 12px;
}

.right-side-form .space-divider {
    width: 10px;
}

.right-side-form textarea,
.right-side-form input {
    background: #F9FAFB;
}

.same-as-block {
    display: flex;
    align-items: flex-start;
}

.right-side-form textarea:disabled,
.right-side-form input:disabled {
    border: 0;
}