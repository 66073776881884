.editor-preview,
.editor-content-container {
    padding: 1em;
    border: 1px solid lightgray;
    background: white;
}

.editor-preview h3 {
    background: #D5F4E2;
    border-left: 10px solid #2ecc71;
    padding: 10px;
    font-size: 22px;
}