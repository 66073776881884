.sticky-table-head {
    position: sticky;
    top: -1em;
}

.add-constant-option {
    justify-content: space-around;
    align-items: center;
}

.max-width-90 {
    max-width: 90px;
    ;
}

.snipp-flex-3 {
    flex: 3;
}

.text-center {
    text-align: center !important;
}