.snipp-drawer-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    right: 0;
}

.snipp-drawer-slider {
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    z-index: 200;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
}

.snipp-drawer-slider.open {
    transform: translateX(0);
}

.snipp-drawer-header {
    align-items: center;
    padding: 1em;
}

.snipp-drawer-header h1 {
    margin: 0;
    /* padding: 0 0.5em; */
}

.snipp-drawer-footer {
    padding: 1em;
    border-top: 1px solid lightgray;
    text-align: right;
}

.snipp-drawer-close {
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
    transition: all 0.3s ease-in;
}

.snipp-drawer-close:hover {
    background: crimson;
    color: white;
}

.snipp-drawer-body {
    padding: 1em;
    overflow: auto;
}